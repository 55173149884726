<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1282_3987" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1282_3987)">
      <path d="M10.7216 19.0205C9.41566 20.3265 7.28523 20.3265 5.97947 19.0205C4.67351 17.7146 4.67351 15.5843 5.97947 14.2784L8.94336 11.3145C10.1883 10.0695 12.1789 10.0008 13.4958 11.1338C13.667 11.2803 13.7471 11.5069 13.706 11.7285C13.6648 11.95 13.5085 12.1328 13.2961 12.2078C13.0837 12.2829 12.8473 12.2389 12.6761 12.0926C11.8473 11.3794 10.623 11.4134 9.83263 12.2037L6.86873 15.1676C6.03995 15.9963 6.03995 17.3029 6.86873 18.1314C7.69737 18.9601 9.00388 18.9601 9.83263 18.1314L12.6481 15.3156C12.7655 15.1953 12.9263 15.1271 13.0945 15.1259C13.2626 15.1249 13.4241 15.1912 13.543 15.3101C13.6618 15.4289 13.7282 15.5905 13.7272 15.7586C13.726 15.9267 13.6577 16.0874 13.5374 16.2048L10.7216 19.0205ZM16.0566 13.6856C14.8116 14.9306 12.8211 14.9993 11.5042 13.8663H11.5043C11.3764 13.7578 11.2969 13.6028 11.2834 13.4356C11.2701 13.2683 11.3238 13.1027 11.4328 12.9752C11.5418 12.8477 11.6972 12.7689 11.8644 12.7562C12.0316 12.7435 12.197 12.798 12.324 12.9075C13.1528 13.6205 14.3771 13.5867 15.1675 12.7964L18.1314 9.83251C18.9601 9.00373 18.9601 7.69737 18.1314 6.86862C17.3027 6.03999 15.9962 6.03999 15.1675 6.86862L12.3518 9.68427C12.2346 9.80503 12.0738 9.87359 11.9055 9.87476C11.7371 9.87593 11.5754 9.8097 11.4564 9.6907C11.3374 9.5717 11.271 9.40987 11.2722 9.2416C11.2733 9.07333 11.3421 8.91252 11.4627 8.79512L14.2783 5.97947C15.5843 4.67351 17.7147 4.67351 19.0205 5.97947C20.3264 7.28542 20.3264 9.41585 19.0205 10.7216L16.0566 13.6856Z" fill="#1E70BB"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconGenerate"
}
</script>